// AuthProvider.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom'; // Importa useHistory de react-router-dom

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const location = useLocation();
    const { hash, pathname, search } = location;
    const history = useHistory(); // Obtiene el objeto history para redirecciones

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Verifica el estado de autenticación al cargar la página
        checkAuthentication();
        history.listen(() => {
            checkAuthentication();
                
        })
    }, []);

    const login = (userData) => {
        setUser(userData);
    };

    const logout = () => {
        setUser(null);
        localStorage.removeItem('token');
    };

    const isAuthenticated = () => {
        return !!user;
    };

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };
    const checkAuthentication = () => {
        // Aquí deberías implementar la lógica para verificar el estado de autenticación
        // Puedes utilizar localStorage o sessionStorage para almacenar el token y su expiración

        // Ejemplo: Verifica si hay un token almacenado
        const token = parseJwt(localStorage.getItem("token"));
        if (token) {
            if (token.exp * 1000 < Date.now()) {
                logout();
                history.push('/auth/signin');
            }
            else {
                setUser(token);
            }
        }
        else if(pathname !== '/auth/signin')
        {
            logout();
            history.push('/auth/signin');
        }

        // if (token && expiration) {
        //   const isTokenExpired = new Date(expiration) < new Date();

        //   if (!isTokenExpired) {
        //     // Si el token no ha expirado, establece el usuario en el estado
        //     setUser({ token });
        //   } else {
        //     // Si el token ha expirado, realiza el logout y redirige a la pantalla de inicio de sesión
        //     logout();
        //     history.push('/login');
        //   }
        // }

        setLoading(false);
    };

    const value = {
        user,
        login,
        logout,
        isAuthenticated,
    };

    // Muestra un componente de carga mientras se verifica la autenticación
    if (loading) {
        return <div>Cargando...</div>;
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
