// Chakra imports
import {
  Flex,
  Text,
  HStack,
  Alert,
  AlertIcon,
  useColorModeValue,
  NumberInput,
  NumberInputField,
  Divider,
  Stack,
  StackDivider,
  Box,
  Heading,
  Button
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
//import TablesTableRow from "components/Tables/TablesTableRow";
import React, {useEffect, useState} from "react";
//import { tablesProjectData, tablesTableData } from "variables/general";
//TABLAS
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
//import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, {  } from 'react-bootstrap-table2-paginator';
import Modal from "react-bootstrap/Modal";
import  "react-datepicker/dist/react-datepicker.css" ;

function IpcRates() {
  var date = new Date();
  date.setDate(date.getDate() - 7);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(new Date());
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  
  function load(){
    fetch(`https://nse-api.staging.tirr.com.ar/api/v1/ipc_rates`, 
          {
            method: "get",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + localStorage.getItem('token'), }
          })
          .then(res => res.json())
          .then(data => setData(data));    
  }
  const [data, setData] = useState([]);
  const [modalStatus, setModalStatus] = useState(false);
  const [periodoM, setPeriodoM] = useState();
  const [periodoY, setPeriodoY] = useState();
  const [porcentaje, setPorcentaje] = useState();
  const [error, setError] = useState('');
  useEffect(()=>{load()},[]);

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Mostrando { from } a { to } de { size } Resultados
    </span>
  );
  const optionsPagination = {  sizePerPageList: [{
                                                    text: '50', value: 50
                                                  }, {
                                                    text: '100', value: 100
                                                  }, {
                                                    text: 'All', value: data.length 
                                                  }],
                                firstPageText: 'Pri',
                                prePageText: 'Vol',
                                nextPageText: 'Sig',
                                lastPageText: 'Ult',
                                nextPageTitle: 'Primera pagina',
                                prePageTitle: 'Pagina Previa',
                                firstPageTitle: 'Siguiente pagina',
                                lastPageTitle: 'Ultima pagina',
                                showTotal: true,
                                paginationTotalRenderer: customTotal,
                                disablePageTitle: true,
                            }

  function saveData(){
    if(Number(porcentaje) <= 0)
    {
      setError('Debe ingresar un valor de porcentaje mayor a cero')
      return ;
    }
    fetch(`https://nse-api.staging.tirr.com.ar/api/v1/ipc_rates/save`, 
    {
      method: "post",
      headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + localStorage.getItem('token'), },
      body: JSON.stringify({
        ipc_period: periodoM+periodoY,
        ipc_rate: porcentaje/100
      })
    })
    .then((respone) => {
      if(respone.status == 200)
      {
        setModalStatus(false)
        load()
      }
    })
    .catch(e => setError('Ocurrio un error guardando los datos, revise la informacion proporcionada')); 
  }

  
  const onAfterSaveCell = (oldValue, newValue, row, column) => {
    fetch(`https://nse-api.staging.tirr.com.ar/api/v1/ipc_rates/update/${row.id_ipc_period}`, 
    {
      method: "post",
      headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + localStorage.getItem('token'), },
      body: JSON.stringify(row)
    }).then(load());  
  }
  const cellEdit = cellEditFactory({
    mode: 'click',
    blurToSave: true,
    afterSaveCell: onAfterSaveCell,
  });
  function cellCurrencyFormatter (cell) {
    return formatter.format(cell);
  };

  

  
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <ToolkitProvider
          keyField="id_ipc_period"
          data={ data
                ? data
                : no_Data }
                columns={[
                  {
                  dataField: "ipc_period",
                  text: "Periodo",
                  sort: true,                 
                  searchable: false,
                  editable: false
                  },                        
                  {
                  dataField: "ipc_rate",
                  text: "Porcentaje",                        
                  sort:true,
                  }
          ]} 
          search
          exportCSV
          //bordered={ true }          
        >
          {
            props => (
              <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
                <CardHeader p="6px 0px 22px 0px">
                    <Text fontSize="xl" color={textColor} fontWeight="bold">
                      Variacion del IPC mensual
                    </Text>
                    <Divider />
                </CardHeader>
                <Flex direction="row" pb={"15px"}>
                  <Button colorScheme='blue' onClick={() => setModalStatus(true)}>Nuevo</Button>
                </Flex>
                <CardBody>
                  <Stack divider={<StackDivider />} spacing='4'>
                    <Box>
                      <Heading size='md' textTransform='uppercase'>
                      <SearchBar { ...props.searchProps } 
                            className="custome-search-field"
                            style={ { color: 'black' } }
                            delay={ 1000 }
                            placeholder="Buscar"
                        />                      
                        <ExportCSVButton { ...props.csvProps }>
                            <Button variant='solid' colorScheme='green'>csv
                            </Button>
                        </ExportCSVButton>  
                      </Heading>
                    </Box>      
                    <BootstrapTable
                      { ...props.baseProps} 
                      cellEdit={ cellEdit }
                      pagination={ paginationFactory(optionsPagination)}                                           
                    />
                  </Stack>    
                </CardBody>
              </Card>
            )
          }
      </ToolkitProvider> 
      <Modal 
          show={modalStatus} 
          onShow={() => {setPeriodoM(null); setPeriodoY(null); setPorcentaje(null)}}
          fullscreen={false}
          >
          <Modal.Header closeButton>
            <Modal.Title>Nuevo Registro</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {error.length > 0? 
              <Alert status='error' show={false}>
                <AlertIcon />
                {error}
              </Alert>
              : ''
            }
            <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
                <CardBody>
                  <Stack divider={<StackDivider />} spacing='4' >
                      <Box>
                        <Text mb='8px'>Periodo: </Text>
                        <HStack spacing='24px' mb={"15px"}>
                          <NumberInput min={1} max={12} >
                            <NumberInputField onChange={(e) => setPeriodoM(e.target.value)} placeholder="1"/>
                          </NumberInput>
                          <Text mb='8px'>/</Text>
                          <NumberInput min={2020} max={2080} >
                            <NumberInputField onChange={(e) => setPeriodoY(e.target.value)} placeholder="2024"/>
                          </NumberInput>
                        </HStack>
                        <Text mb='8px'>Porcentaje: </Text>
                        <NumberInput precision={2} >
                          <NumberInputField onChange={(e) => setPorcentaje(e.target.value)}/>
                        </NumberInput>
                      </Box>     
                  </Stack>    
                </CardBody>
            </Card> 
        
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" 
              onClick={(e)=>{
                e.preventDefault();
                setModalStatus(false)
              }}>
              Salir
            </Button>
            <Button variant="primary"
              onClick={(e)=>{
                e.preventDefault();
                saveData();
              }} >
              Guardar
            </Button>
          </Modal.Footer>
      </Modal>

    </Flex>

  );
}

export default IpcRates;
