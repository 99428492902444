import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react"
//import React from "react";
import React, { useState} from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/signInImage.png";
import axios from 'axios';
import { useHistory } from 'react-router-dom';



function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const bgForm = useColorModeValue("white", "navy.800"); 

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const history = useHistory();

  const handleLogin = async () => {
    try {    
      const response = await axios.post(`https://nse-api.staging.tirr.com.ar/api/v1/usuarios/login`, {
        email: username,
        password: password,
      });
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('usuario', response.data.user.id_user);
      localStorage.setItem('nombre', `${response.data.user.first_name} ${response.data.user.last_name}`);
      history.push('/admin');
    } catch (err) {
      // Manejar el error de autenticación      
      console.error('Error de autenticación:', err.message);
      setError('Credenciales inválidas');
      setShowErrorAlert(true);
    }
  };


  return (
    <Flex position='relative' mb='40px'>
      <Flex
        minH={{ md: "1000px" }}
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{ md: "0px" }}>           

        <Flex
          w='100%'
          h='100%'
          alignItems='center'
          justifyContent='center'
          mb='60px'
          mt={{ base: "50px", md: "20px" }}>
          <Flex
            zIndex='2'
            direction='column'
            w='445px'
            background='transparent'
            borderRadius='15px'
            p='40px'
            mx={{ base: "100px" }}
            m={{ base: "20px", md: "auto" }}
            bg={bgForm}
            boxShadow={useColorModeValue(
              "0px 5px 14px rgba(0, 0, 0, 0.05)",
              "unset"
            )}>
            <Text
              fontSize='xl'
              color={textColor}
              fontWeight='bold'
              textAlign='center'
              mb='22px'>
              Bienvenido
            </Text>
                     
            <FormControl>          
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Usuario
              </FormLabel>              
              <Input
                variant='auth'
                fontSize='sm'
                ms='4px'
                type='text'
                placeholder='Usuario'
                value={username} onChange={(e) => setUsername(e.target.value)}
                mb='24px'
                size='lg'
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Contraseña
              </FormLabel>
              <Input
                variant='auth'
                fontSize='sm'
                ms='4px'
                type='password'
                placeholder='Contraseña'
                value={password} onChange={(e) => setPassword(e.target.value)}
                mb='24px'
                size='lg'
              />             
              <Button
                fontSize='10px'
                variant='dark'
                fontWeight='bold'
                w='100%'
                h='45'
                mb='24px'
                onClick={handleLogin}
                >
                INGRESAR
              </Button>
              <div>
              {showErrorAlert && (
                <Alert status="error" onClose={() => setShowErrorAlert(false)}>
                  <AlertIcon />
                  <AlertTitle mr={2}>¡Error!</AlertTitle>
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}
              </div>
            </FormControl>            
          </Flex>
        </Flex>
        <Box
          overflowX='hidden'
          h='100%'
          w='100%'
          left='0px'
          position='absolute'
          bgImage={signInImage}>
          <Box
            w='100%'
            h='100%'
            bgSize='cover'
            bg='blue.500'
            opacity='0.8'></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
