// import
import React, { Component }  from 'react';
import Dashboard from "views/Dashboard/Dashboard.js";
import SignIn from "views/Pages/SignIn.js";
import SignUp from "views/Pages/SignUp.js";
import Assumptions from 'views/Dashboard/Assumptions';
import MonotributesIncomes from 'views/Dashboard/MonotributesIncomes';
import IpcRates from 'views/Dashboard/IpcRates';
import ProvincesIncomes from 'views/Dashboard/ProvincesIncomes';
import ProvincesIncomesClae2 from 'views/Dashboard/ProvincesIncomesClae2';
import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";


var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/assumptions",
    name: "Assumptions",
    //rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: Assumptions,
    layout: "/admin",
  },
  {
    path: "/monotributes",
    name: "Ingresos Monotributo",
    //rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: MonotributesIncomes,
    layout: "/admin",
  },
  {
    path: "/ipc_rates",
    name: "Variacion IPC",
    icon: <StatsIcon color='inherit' />,
    component: IpcRates,
    layout: "/admin",
  },
  {
    path: "/provinces_incomes",
    name: "Ingresos x Provincia",
    icon: <StatsIcon color='inherit' />,
    component: ProvincesIncomes,
    layout: "/admin",
  },
  {
    name: "Configuracion",
    category: "account",    
    state: "pageCollapse",
    views: [
      {
         path: "/signin",
         name: "Ingresar",         
         icon: <DocumentIcon color='inherit' />,
         component: SignIn,
         layout: "/auth",
         disabled: true
      },
      {
        path: "/signup",
        name: "Salir",        
        icon: <RocketIcon color='inherit' />,
        component: SignUp,
        layout: "/auth",
      },
    ],
  },
];
export default dashRoutes;
