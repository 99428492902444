// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Progress,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import BarChart from "components/Charts/BarChart";
import LineChart from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";
import _ from "lodash";
// Custom icons
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  WalletIcon,
} from "components/Icons/Icons.js";
import React,{ useState, useEffect }  from "react";
// Variables
import {
  barChartData,
  barChartOptions,
  lineChartData,
  lineChartOptions,
} from "variables/charts";
import { pageVisits, socialTraffic } from "variables/general";
import { Link } from "react-router-dom";


export default function Dashboard() {
  //Data Section
  
  function loadInicio(){
    fetch(`https://nse-api.staging.tirr.com.ar/api/v1/dashboard`,
    {
      method: "get",
      headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + localStorage.getItem('token'), },
    })
    .then(res => res.json())
    .then(data => {
      console.log(data.monotributes[0]);
      console.log(_.isEmpty(data));
      setInicio(data)
    });    
  }
  const [inicio, setInicio] = useState([]);
  useEffect(()=>{loadInicio()},[]);

  
  // Chakra Color Mode
  const iconBlue = useColorModeValue("blue.500", "blue.500");
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");
  const tableRowColor = useColorModeValue("#F7FAFC", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const textTableColor = useColorModeValue("gray.500", "white");
  const { colorMode } = useColorMode();
  
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return ( 
    <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px' mb='20px'>
        <Card minH='125px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'>
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                   Importe Monotributo Maxima Categoria
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                    {!_.isEmpty(inicio) ? formatter.format(inicio.monotributes[0].year_gross_income) : "0"}
                  </StatNumber>
                </Flex>
              </Stat>
              <Link to={{pathname: `/admin/monotributes`}} 
                    state={{ param: 'si'}}>
                <IconBox
                  borderRadius='50%'
                  as='box'
                  h={"45px"}
                  w={"45px"}
                  bg={iconBlue}>
                  <WalletIcon h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Link>
            </Flex>
            <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                Categoria:{" "}
              </Text>
              {!_.isEmpty(inicio) ? inicio.monotributes[0].category_code : ""}
            </Text>
          </Flex>
        </Card>
      </SimpleGrid>
    </Flex>
  );
}
