
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,  
  Text,
  useColorModeValue,  
} from "@chakra-ui/react";
// Assets
import BgSignUp from "assets/img/BgSignUp.png";
import React from "react";
import { useHistory } from 'react-router-dom';
import { useAuth } from "AuthProvider";

function SignUp() {
  const { logout } = useAuth();
  React.useEffect(() => {
    logout();
    // Redirige a la página de inicio o a la que desees después del logout
    window.location.replace('/');
  }, [logout]);

  return <p>Cerrando sesión...</p>;
  
}

export default SignUp;
