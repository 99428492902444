// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  Divider,
  Stack,
  StackDivider,
  Box,
  Heading,
  Button
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
//import TablesTableRow from "components/Tables/TablesTableRow";
import React, {useEffect, useState} from "react";
//import { tablesProjectData, tablesTableData } from "variables/general";
//TABLAS
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
//import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, {  } from 'react-bootstrap-table2-paginator';
import  "react-datepicker/dist/react-datepicker.css" ;

function Assumptions() {
  var date = new Date();
  date.setDate(date.getDate() - 7);
  //console.log(props);
  const textColor = useColorModeValue("gray.700", "white");
  
  function load(){
    fetch(`https://nse-api.staging.tirr.com.ar/api/v1/assumptions`, 
          {
            method: "get",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + localStorage.getItem('token'), }
          })
          .then(res => res.json())
          .then(data => setData(data));    
  }
  const [data, setData] = useState([]);
  useEffect(()=>{load()},[]);

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Mostrando { from } a { to } de { size } Resultados
    </span>
  );
  const optionsPagination = {  sizePerPageList: [{
                                                    text: '50', value: 50
                                                  }, {
                                                    text: '100', value: 100
                                                  }, {
                                                    text: 'All', value: data.length 
                                                  }],
                                firstPageText: 'Pri',
                                prePageText: 'Vol',
                                nextPageText: 'Sig',
                                lastPageText: 'Ult',
                                nextPageTitle: 'Primera pagina',
                                prePageTitle: 'Pagina Previa',
                                firstPageTitle: 'Siguiente pagina',
                                lastPageTitle: 'Ultima pagina',
                                showTotal: true,
                                paginationTotalRenderer: customTotal,
                                disablePageTitle: true,
                            }

  const onAfterSaveCell = (oldValue, newValue, row) => {
    if(oldValue === newValue) return;
    fetch(`https://nse-api.staging.tirr.com.ar/api/v1/assumptions/update/${row.id_assumption}`, 
    {
      method: "post",
      headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + localStorage.getItem('token'), },
      body: JSON.stringify(row)
    })
    .then(res => res.json())
    .then(res => {
      const newData = data.map(obj => {
        if(res.id_assumption === obj.id_assumption)
          return res;
        return obj
      });
      setData(newData);
    })
  }
  const cellEdit = cellEditFactory({
    mode: 'click',
    blurToSave: true,
    afterSaveCell: onAfterSaveCell,
  });
 
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <ToolkitProvider
          keyField="id_assumption"
          data={ data
                ? data
                : no_Data }
                columns={[
                  {
                  dataField: "code",
                  text: "Codigo",
                  sort: true,                 
                  searchable: false,
                  editable: false
                  },                        
                  {
                  dataField: "name",
                  text: "Nombre",                        
                  sort:true,
                  },
                  {
                  dataField: "value_type",
                  text: "Tipo Valor",
                  sort:true,
                  },
                  {
                    dataField: "value",
                    text: "Valor",
                    sort:true,
                  },
          ]} 
          search
          exportCSV
          //bordered={ true }          
        >
          {
            props => (
              <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
                <CardHeader p="6px 0px 22px 0px">
                    <Text fontSize="xl" color={textColor} fontWeight="bold">
                      Assumptions
                    </Text>
                    <Divider />
                </CardHeader>
                
                <CardBody>
                  <Stack divider={<StackDivider />} spacing='4'>
                    <Box>
                      <Heading size='md' textTransform='uppercase'>
                      <SearchBar { ...props.searchProps } 
                            className="custome-search-field"
                            style={ { color: 'black' } }
                            delay={ 1000 }
                            placeholder="Buscar"
                        />                      
                        <ExportCSVButton { ...props.csvProps }>
                            <Button variant='solid' colorScheme='green'>csv
                            </Button>
                        </ExportCSVButton>  
                      </Heading>
                    </Box>      
                    <BootstrapTable
                      { ...props.baseProps} 
                      cellEdit={ cellEdit }
                      pagination={ paginationFactory(optionsPagination)}                                           
                    />
                  </Stack>    
                </CardBody>
              </Card>
            )
          }
      </ToolkitProvider> 
    </Flex>

  );
}

export default Assumptions;
